const INSURANCE_FAQ_REQUEST = "INSURANCE_FAQ_REQUEST";
const INSURANCE_FAQ_SUCCESS = "INSURANCE_FAQ_SUCCESS";
const INSURANCE_FAQ_FAILURE = "INSURANCE_FAQ_FAILURE";

const initialState = {
  fetching: false,
  data: null,
  error: null,
};

const InsuranceFAQ = (state = initialState, action) => {
  switch (action.type) {
    case INSURANCE_FAQ_REQUEST:
      return { ...state, fetching: true, error: null };
    case INSURANCE_FAQ_SUCCESS:
      return { ...state, fetching: false, data: action.data };
    case INSURANCE_FAQ_FAILURE:
      return { ...state, fetching: false, data: null, error: action.error };
    default:
      return state;
  }
};

export default InsuranceFAQ;
