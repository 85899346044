import { combineReducers } from "redux";
import InsuranceFAQ from "./InsuranceFAQ";
import InsuranceUF from "./InsuranceUF";
import InsuranceTypes from "./InsuranceTypes";
import InsuranceByTypeInsurance from "./InsuranceByTypeInsurance";
import InsuranceComparator from "./InsuranceComparator";
export default combineReducers({
  InsuranceFAQ,
  InsuranceUF,
  InsuranceTypes,
  InsuranceByTypeInsurance,
  InsuranceComparator,
});
