import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

function fechaString(fecha) {
  return new Date(fecha).toLocaleString('es-CL', { timeZone: 'UTC', dateStyle: 'medium'}).split('-').join('/');
}
function ApiUF(props) {
  const dispatch = useDispatch();
  const insuranceUF = useSelector((state) => state.InsuranceUF);

  useEffect(() => {
    dispatch({
      type: "INSURANCE_UF_REQUEST",
    });
  }, [dispatch]);

  function TextoUF(props) {
    const Tipo = props.Tipo;
    if (Tipo==="1") {  return <span>Valores en pesos son referenciales a la UF del {fechaString(insuranceUF.data.id.publicationDate)}, ${insuranceUF.data.value}</span>; }
    if (Tipo==="2") {  return <span>Valores en pesos son referenciales al valor de la UF del día {fechaString(insuranceUF.data.id.publicationDate)}</span>; }
    if (Tipo==="3") {  return <span>Valor UF ${insuranceUF.data.value} al {fechaString(insuranceUF.data.id.publicationDate)}</span>; }
    return "";
  }
  return (
    <li className="Uf">
    {!insuranceUF.fetching && insuranceUF.data && ( <TextoUF Tipo={props.Tipo} />
    )}
    </li>     
  );
}
export default ApiUF;